export class UrlBuilder {
	constructor(private urlBackendBase: string) {}

	getBackendUrl(relative: string) {
		return this.urlBackendBase + relative;
	}
}

export class Routes {
	static dashboard = '/';
	static complianceHistory = '/complianceHistory';
	static velocityHistory = '/velocityHistory';
	static contemporaneousHistory = '/contemporaneousHistory';
	static timescoreBreakdown = '/timescoreBreakdown';
	static workHoursOverview = '/workHoursOverview';
	static penaltiesAndRewards = '/penaltiesandrewards';
	static policyEditor = '/policyeditor';
	static policyEditorVelocity = `${Routes.policyEditor}/velocity`;
	static policyEditorDeadlineDiscipline = `${Routes.policyEditor}/deadlinediscipline`;
	static policyEditorGraceperiod = `${Routes.policyEditor}/graceperiod`;
	static policyEditorEngagement = `${Routes.policyEditor}/engagement`;
	static policyEditorUsers = `${Routes.policyEditor}/users`;
	static policyEditorTimescore = `${Routes.policyEditor}/timescore`;
	static notAuthorized = '/notauthorized';
	static noPolicyOwnerRoutes = '/nopolicyownerpolicies';
	static noEnabledPolicies = '/noenabledpolicies';

	static getPolicyEditorRoutes = () =>
		Object.values(Routes)
			.filter((r) => typeof r === 'string')
			.filter((r) => r.includes(Routes.policyEditor));

	static isPolicyRoute = (route: string) => Routes.getPolicyEditorRoutes().includes(route.split('#')[1]);
}

export class BackendRoutes {
	static dashboard = 'policies/getsubscriptionpolicydashboardperiodcomparison';
	static compliancehistory = 'policies/getsubscriptionpolicyrecentcompliancehistory';
	static velocityHistory = 'policies/getsubscriptionvelocityhistory';
	static contemporaneousHistory = 'policies/getsubscriptionpolicyrecentcontemporaneoushistory';
	static policiesforsubscription = 'policies/getpoliciesforsubscription';
	static policiesforsubscriptionhr = 'policies/gettimeentrypoliciesforsubscriptionforhrpolicy';
	static periodsforpolicy = 'policies/getsubscriptionpolicyperiods';
	static periodsforpolicywithcurrentperiod = 'policies/getsubscriptionpolicyperiodswithcurrentperiod';
	static timescoresummaryforperiod = 'policies/getpolicytimescoresummaryforperiod';
	static usertimescoresforperiod = 'policies/getpolicyusertimescoresforperiod';
	static usertimescorehistory = 'policies/getsubscriptionpolicyrecenttimescoreforuserhistory';
	static userscomplianceresults = 'policies/getsubscriptionpolicycomplianceresultusersformatted';
	static userscontemporaneousresults = 'policies/getsubscriptionpolicycontemporaneousresultsforperiod';
	static usersgoals = 'policies/getpolicyusergoal';
	static getPolicyById = 'policies/getpolicybyid';
	static editPolicy = 'policies/editpolicy';
	static finalizePolicy = 'policies/finalizepolicy';
	static disablePolicy = 'policies/disablepolicy';
	static duplicatePolicy = 'policies/duplicatepolicy';
	static deletePolicy = 'policies/deletepolicy';
	static addPolicy = 'policies/addpolicy';
	static isPolicyEnabled = 'policies/ispolicyenabled';
	static getCampaignsProperties = 'policies/getallthrivecampaignenabledstatus';
	static setCampaignsProperties = 'policies/setallthrivecampaignenabledstatus';
	static renewToken = 'jwttoken/renew';
	static getPolicyUsers = 'policies/getthriveusers';
	static getPolicyOwners = 'policies/getthriveowners';
	static addUserToPolicy = 'policies/addusertopolicy';
	static addOwnerToPolicy = 'policies/addownertopolicy';
	static removeUserFromPolicy = 'policies/removeuserfrompolicy';
	static removeOwnerFromPolicy = 'policies/removeownerfrompolicy';
}

export class ItkPropertiesRoutes {
	static getProperties = 'getvalues';
	static setProperties = 'setvalues';
}

export enum EnvironmentKind {
	mocked = 'mocked',
	devNorthAmerica = 'DEV-NORTH-AMERICA',
	qaNorthAmerica = 'QA-NORTH-AMERICA',
	stagingNorthAmerica = 'STAGING-NORTH-AMERICA',
	prodNorthAmerica = 'PROD-NORTH-AMERICA',
	prodUk = 'PROD-UK',
	prodCanada = 'PROD-CANADA',
	prodAustralia = 'PROD-AUSTRALIA',
	prodEu = 'PROD-EU',
}

enum Domain {
	devNorthAmerica = 'https://thrivedev.z13.web.core.windows.net/',
	qaNorthAmerica = 'https://thriveqa.z13.web.core.windows.net/',
	stagingNorthAmerica = 'https://thrivestaging.z13.web.core.windows.net/',
	prodNorthAmerica = 'https://thrive.aderant.com',
	prodUk = 'https://thrive.aderant.co.uk',
	prodCanada = 'https://thrive.aderant.ca',
	prodAustralia = 'https://thrive.aderant.com.au',
	prodEu = 'https://thrive.aderant.eu',
	local = 'http://localhost:3000',
}

enum EndpointBase {
	devNorthAmerica = 'https://devcloud.bellefield.com/rest/services/thrive/v3/',
	qaNorthAmerica = 'https://qaitimekeep.aderant.com/rest/services/thrive/v3/',
	stagingNorthAmerica = 'https://stagecloud.bellefield.com/rest/services/thrive/v3/',
	prodNorthAmerica = 'https://services.bellefieldcloud.com/rest/services/thrive/v3/',
	prodUk = 'https://itimekeep.aderant.co.uk/rest/services/thrive/v3/',
	prodCanada = 'https://itimekeep.aderant.ca/rest/services/thrive/v3/',
	prodAustralia = 'https://itimekeep.aderant.com.au/rest/services/thrive/v3/',
	prodEu = 'https://itimekeep.aderant.eu/rest/services/thrive/v3/',
}

enum EndpointBasePropertiesItk {
	devNorthAmerica = 'https://devcloud.bellefield.com/rest/services/itimekeep/v3/properties/',
	qaNorthAmerica = 'https://qaitimekeep.aderant.com/rest/services/itimekeep/v3/properties/',
	stagingNorthAmerica = 'https://stagecloud.bellefield.com/rest/services/itimekeep/v3/properties/',
	prodNorthAmerica = 'https://services.bellefieldcloud.com/rest/services/itimekeep/v3/properties/',
	prodUk = 'https://itimekeep.aderant.co.uk/rest/services/itimekeep/v3/properties/',
	prodCanada = 'https://itimekeep.aderant.ca/rest/services/itimekeep/v3/properties/',
	prodAustralia = 'https://itimekeep.aderant.com.au/rest/services/itimekeep/v3/properties/',
	prodEu = 'https://itimekeep.aderant.eu/rest/services/itimekeep/v3/properties/',
}

enum EndpointPortalLogin {
	devNorthAmerica = 'https://devcloud.bellefield.com/portal/User/SingleSignOn/JWTGenerator.aspx',
	qaNorthAmerica = 'https://qaitimekeep.aderant.com/portal/User/SingleSignOn/JWTGenerator.aspx',
	stagingNorthAmerica = 'https://stagecloud.bellefield.com/portal/User/SingleSignOn/JWTGenerator.aspx',
	prodNorthAmerica = 'https://services.bellefieldcloud.com/portal/User/SingleSignOn/JWTGenerator.aspx',
	prodUk = 'https://itimekeep.aderant.co.uk/portal/User/SingleSignOn/JWTGenerator.aspx',
	prodCanada = 'https://itimekeep.aderant.ca/portal/User/SingleSignOn/JWTGenerator.aspx',
	prodAustralia = 'https://itimekeep.aderant.com.au/portal/User/SingleSignOn/JWTGenerator.aspx',
	prodEu = 'https://itimekeep.aderant.eu/portal/User/SingleSignOn/JWTGenerator.aspx',
}

export class Environment {
	private constructor(
		readonly domain: Domain,
		readonly endpointBase: EndpointBase,
		readonly endpointBasePropertiesItk: EndpointBasePropertiesItk,
		readonly endpointPortalLogin: EndpointPortalLogin
	) {}

	static createForStagingNorthAmerica() {
		return new Environment(
			Domain.stagingNorthAmerica,
			EndpointBase.stagingNorthAmerica,
			EndpointBasePropertiesItk.stagingNorthAmerica,
			EndpointPortalLogin.stagingNorthAmerica
		);
	}

	static createForDevNorthAmerica() {
		return new Environment(
			Domain.devNorthAmerica,
			EndpointBase.devNorthAmerica,
			EndpointBasePropertiesItk.devNorthAmerica,
			EndpointPortalLogin.devNorthAmerica
		);
	}

	static createForQANorthAmerica() {
		return new Environment(
			Domain.qaNorthAmerica,
			EndpointBase.qaNorthAmerica,
			EndpointBasePropertiesItk.qaNorthAmerica,
			EndpointPortalLogin.qaNorthAmerica
		);
	}

	static createForProdNorthAmerica() {
		return new Environment(
			Domain.prodNorthAmerica,
			EndpointBase.prodNorthAmerica,
			EndpointBasePropertiesItk.prodNorthAmerica,
			EndpointPortalLogin.prodNorthAmerica
		);
	}

	static createForProdUk() {
		return new Environment(
			Domain.prodUk,
			EndpointBase.prodUk,
			EndpointBasePropertiesItk.prodUk,
			EndpointPortalLogin.prodUk
		);
	}

	static createForProdCanada() {
		return new Environment(
			Domain.prodCanada,
			EndpointBase.prodCanada,
			EndpointBasePropertiesItk.prodCanada,
			EndpointPortalLogin.prodCanada
		);
	}

	static createForProdAustralia() {
		return new Environment(
			Domain.prodAustralia,
			EndpointBase.prodAustralia,
			EndpointBasePropertiesItk.prodAustralia,
			EndpointPortalLogin.prodAustralia
		);
	}

	static createForProdEu() {
		return new Environment(
			Domain.prodEu,
			EndpointBase.prodEu,
			EndpointBasePropertiesItk.prodEu,
			EndpointPortalLogin.prodEu
		);
	}

	static isStagingNorthAmerica() {
		return window.location.href.includes(Domain.stagingNorthAmerica);
	}

	static isDevNorthAmerica() {
		return window.location.href.includes(Domain.devNorthAmerica);
	}

	static isQANorthAmerica() {
		return window.location.href.includes(Domain.qaNorthAmerica);
	}

	static isProdNorthAmerica() {
		return window.location.href.includes(Domain.prodNorthAmerica);
	}

	static isProdUk() {
		return window.location.href.includes(Domain.prodUk);
	}

	static isProdCanada() {
		return window.location.href.includes(Domain.prodCanada);
	}

	static isProdAustralia() {
		return window.location.href.includes(Domain.prodAustralia);
	}

	static isProdEu() {
		return window.location.href.includes(Domain.prodEu);
	}

	static isLocal() {
		return ['localhost', '0.0.0.0', '127.0.0.1'].some((dh) => window.location.hostname === dh);
	}

	static getCurrentEnvironment() {
		const debugEnv = this.getDebugEnvironment();

		if (debugEnv != null) return debugEnv;

		if (this.isLocal() || this.isDevNorthAmerica()) return EnvironmentKind.devNorthAmerica;

		if (this.isQANorthAmerica()) return EnvironmentKind.qaNorthAmerica;

		if (this.isStagingNorthAmerica()) return EnvironmentKind.stagingNorthAmerica;

		if (this.isProdNorthAmerica()) return EnvironmentKind.prodNorthAmerica;

		if (this.isProdUk()) return EnvironmentKind.prodUk;

		if (this.isProdCanada()) return EnvironmentKind.prodCanada;

		if (this.isProdAustralia()) return EnvironmentKind.prodAustralia;

		if (this.isProdEu()) return EnvironmentKind.prodEu;
	}

	private static getDebugEnvironment() {
		const value = (() => {
			const item = window.localStorage.getItem('debug.environment');
			return item ? item.toLowerCase() : item;
		})();

		const env = (() => {
			switch (value) {
				case 'mocked':
					return EnvironmentKind.mocked;
				case 'devnorthamerica':
					return EnvironmentKind.devNorthAmerica;
				case 'prodnorthamerica':
					return EnvironmentKind.prodNorthAmerica;
			}
		})();

		if (env) console.log(`Debug environment '${env}' found!`);
		return env;
	}
}
